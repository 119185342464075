import React from "react"
import _ from "lodash"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import * as tagStyles from "./tags.module.css"

const AllTagsIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const tags = data.tags.distinct

  return (
    <Layout location={location} title={siteTitle} data={data}>
      <SEO title="All tags" />

      <div className={tagStyles.tagKeyDiv}>
        <p className={tagStyles.tagKey}>#allTags</p>
      </div>

      {/* Spacer */}
      <div style={{ height: "20px" }}></div>

      <div className={tagStyles.tagsDiv}>
        {tags.map((tag) => {
          if (tag === "travel") {
            return (
              <Link
                key={tag}
                className={tagStyles.tag}
                to={`/${_.kebabCase(tag)}`}
              >
                {tag}
              </Link>
            )
          }
          return (
            <Link
              key={tag}
              // style={{ textDecoration: "none" }}
              className={tagStyles.tag}
              to={`/tags/${_.kebabCase(tag)}`}
            >
              {tag}
            </Link>
          )
        })}
      </div>
    </Layout>
  )
}

export default AllTagsIndex

export const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    tags: allMarkdownRemark {
      distinct(field: frontmatter___tags)
    }
  }
`
